const validator = require("email-validator");
const tlds = require('tlds');

export const valueDoesExist = (value) => {
    return !(value === false || value === "" || value === null || value === undefined);
}

//TODO: make validate email error better to give the users more information
export const validateEmail = (email) => {
    //catch no email and any entry that is not a string
    if(!email) {
        return false
    } else if(email && typeof email !== "string"){
        return false
    }
    let splitEmail = email.split(".");
    let emailEnding = splitEmail[splitEmail.length - 1];
    //validate if proper syntax, also check tlds list to see if it exists
    return !!(email && validator.validate(email) && emailEnding && tlds.indexOf(emailEnding) !== -1);
}

export const isObjectEmpty = (obj) =>  {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

function checkIfTimeStampIsInSecondsAndConvert(timeStamp){
    //check if timeStamp is in seconds or milliseconds
    //Its 2020 so for now we can assume milliseconds if timestamp has 13 or more digits
    //This should work until the year 33658 so thats good
    //is milliseconds and javascript prefers milliseconds
    let stringTimeStampLength = 0;
    if(typeof timeStamp !== "string"){
        stringTimeStampLength = timeStamp.toString().length;
    } else {
        stringTimeStampLength = timeStamp.length;
        timeStamp = parseInt(timeStamp);
    }
    if(stringTimeStampLength < 13){
        //is seconds convert to milliseconds
        return timeStamp*1000
    } else {
        return timeStamp
    }
}

function toStandardTime(value) {
    if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
            return value;
        }
        else {
            if(value.length === 8){ //If value is the expected length for military time then process to standard time.
                let hour = parseInt(value.substring ( 0,2 )); //Extract hour
                const minutes = value.substring ( 3,5 ); //Extract minutes
                let identifier = 'AM'; //Initialize AM PM identifier

                if(hour === 12){ //If hour is 12 then should set AM PM identifier to PM
                    identifier = 'PM';
                }
                if(hour === 0){ //If hour is 0 then set to 12 for standard time 12 AM
                    hour=12;
                }
                if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                    hour = hour - 12;
                    identifier='PM';
                }
                return hour.toString() + ':' + minutes + ' ' + identifier; //Return the constructed standard time
            }
            else { //If value is not the expected length than just return the value as is
                return value;
            }
        }
    }
}

export const handleStorageException = (e) => {
    let messageException = "";
    if(e.code === 18){
        messageException = "Local storage is disabled, please allow all cookies in your browser";
    } else if(isQuotaExceeded(e)){
        messageException = "Local storage is full, clear some space on your browser";
    }
    return messageException;
}

function isQuotaExceeded(e) {
    let quotaExceeded = false;
    if (e) {
        if (e.code) {
            switch (e.code) {
                case 22:
                    quotaExceeded = true;
                    break;
                case 1014:
                    // Firefox
                    if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                        quotaExceeded = true;
                    }
                    break;
            }
        } else if (e.number === -2147024882) {
            // Internet Explorer 8
            quotaExceeded = true;
        }
    }
    return quotaExceeded;
}

export const convertTimeStampToHumanReadable = (timeStamp) => {
    if(!timeStamp){
        return null
    }
    const dateToConvert = new Date(checkIfTimeStampIsInSecondsAndConvert(timeStamp));
    const formattedTimeSplit = dateToConvert.toString().split(" ");
    formattedTimeSplit[4] = toStandardTime(formattedTimeSplit[4]);
    //remove bullshit returned by date
    formattedTimeSplit.splice(5,4);
    //get short timezone
    formattedTimeSplit.push(dateToConvert.toLocaleString('en', {timeZoneName:'short'}).split(' ').pop());
    return formattedTimeSplit.join(" ");
};
