import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';
import BigScreen from './components/main_screens/BigScreen';
import SideBigScreen from './components/main_screens/SideBigScreen';
import CustomScoreboard from './components/main_screens/CustomScoreboard';
import CustomScoreboardHorizontal from './components/main_screens/CustomScoreboardHorizontal';
import FallbackLocalStorage from "fallback-local-storage";

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        this.checkForAgeGate = this.checkForAgeGate.bind(this)
        let userEmail;
        try {
            userEmail = this.state.appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false,
            appStorage: null
        }
    }

    setCurrentUser() {
        const userEmail = this.state.appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        let appStorage;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = window.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = window.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
        return appStorage;
    }

    componentDidMount() {
      this.getTenantVariables();
    }

    getTenantVariables(){
      let langague = "english"
      let splitWindowLocation = window.location.pathname.split('/') || ""
      if(window.location.pathname.split('/').length > 2){
          langague = splitWindowLocation[2]
      } else if(window.location.pathname.split('/').length > 1){
          langague = splitWindowLocation[1]
      }
      this.loadLanguage(langague)
      this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
          context: this,
          state: 'tenantVariables',
          then(variables){
              document.title = variables.pageTitle || "Trivia";
              const appStorage = this.setUpStorage(variables);
              this.setState({
                  authenticated: false,
                  variables: variables,
                  loading: false,
                  langague: langague,
                  appStorage: appStorage
              })
          }
      });
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = this.state.appStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

    async loadLanguage(langauge){
        let languagesConstants;
        if(langauge){
            try {
                languagesConstants = await import("./constants/languages/"+langauge+".js");
            } catch(e) {
                langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
                languagesConstants = await import("./constants/languages/"+langauge+".js");
            }
        } else {
            langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
            languagesConstants = await import("./constants/languages/"+langauge+".js");
        }
        this.setState({
            lanaguageConstants: languagesConstants
        })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        let redirectUrl = `/login`;
        if(this.state.langague){
          redirectUrl += ("/" + this.state.langague);
        }
        const appStorage = this.state.appStorage;
        console.log(appStorage)
        return (
            <BrowserRouter>
              <Switch>
                <Route
                    strict
                    path="/login"
                    render={(props) => {
                        return <Login appStorage={appStorage} authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} stringConstants={this.state.lanaguageConstants} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />
                  <Route
                      exact
                      path="/bigscreen"
                      render={(props) => {
                          return <BigScreen variables={this.state.variables} {...props} />
                      }}
                  />
                  <Route
                      exact
                      path="/sidebigscreen"
                      render={(props) => {
                          return <SideBigScreen variables={this.state.variables} {...props} />
                      }}
                  />
                  <Route
                      exact
                      path="/customscoreboard"
                      render={(props) => {
                          return <CustomScoreboard variables={this.state.variables} {...props} />
                      }}
                  />
                  <Route
                      exact
                      path="/customscoreboardhorizontal"
                      render={(props) => {
                          return <CustomScoreboardHorizontal variables={this.state.variables} {...props}/>
                      }}
                  />
                  <Route
                      strict
                      path="/age_gate"
                      render={(props) => {
                          return <AgeVerification appStorage={appStorage} loadPage={() => logPageView()} stringConstants={this.state.lanaguageConstants} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                      }}
                  />

                  <AuthenticatedRoute
                      exact
                      path="/"
                      authenticated={this.state.authenticated}
                      variables={this.state.variables}
                      passedEmail={this.state.passedEmail}
                      loadPage={() => logPageView()}
                      checkForAgeGate={this.checkForAgeGate}
                      stringConstants={this.state.lanaguageConstants}
                      appStorage={appStorage}
                      component={MainApp} />

                  <Redirect to={redirectUrl}/>
              </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
